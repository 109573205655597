import React from "react";
import { Col, Container, Row } from "react-bootstrap";


const ServiceSection = () => {
  return (
    <Container fluid className="home-goals-section py-5" id="goals">
        {/* <Row>
          <Col md={12} xs={{ order: 1 }} className="home-about-description">
            <h1 className="purplec clamp1cc" style={{ fontWeight: "bold" }}>
              CLIENTS SAY{" "}
            </h1>
          
          </Col>
        </Row>
        <Row>
        <div class="marquee marquee--8">
          <Col class="marquee__item home-about-description d-flex justify-content-start gap-5 align-items-center flex-column" >
          <p
              className="clamp2cc"
              style={{
                padding: "10px",
                background: "#be50f4",
                borderRadius: "10px",
                textAlign: "center",
              }}
            >
              Good carriers. Their truck broke down on the way for the pickup, and they send another truck immediately, and picked up the load on time, so delivered it on time. The driver was super polite...
            </p>
          </Col>
          <Col class="marquee__item home-about-description d-flex justify-content-start gap-5 align-items-center flex-column" >
          <p
              className="clamp2cc"
              style={{
                padding: "10px",
                background: "#be50f4",
                borderRadius: "10px",
                textAlign: "center",
              }}
            >
              Good carriers. Their truck broke down on the way for the pickup, and they send another truck immediately, and picked up the load on time, so delivered it on time. The driver was super polite...
            </p>
          </Col>
          <Col class="marquee__item home-about-description d-flex justify-content-start gap-5 align-items-center flex-column" >
          <p
              className="clamp2cc"
              style={{
                padding: "10px",
                background: "#be50f4",
                borderRadius: "10px",
                textAlign: "center",
              }}
            >
              Good carriers. Their truck broke down on the way for the pickup, and they send another truck immediately, and picked up the load on time, so delivered it on time. The driver was super polite...
            </p>
          </Col>
          <Col class="marquee__item home-about-description d-flex justify-content-start gap-5 align-items-center flex-column" >
          <p
              className="clamp2cc"
              style={{
                padding: "10px",
                background: "#be50f4",
                borderRadius: "10px",
                textAlign: "center",
              }}
            >
              Good carriers. Their truck broke down on the way for the pickup, and they send another truck immediately, and picked up the load on time, so delivered it on time. The driver was super polite...
            </p>
          </Col>
        </div>
        </Row> */}
    </Container>
  );
};

export default ServiceSection;
